import React, { useState, useEffect, useRef } from 'react'
import { isMobile, isAndroid, isIOS } from 'react-device-detect'
import ReactPlayer from 'react-player'
import { PhotoAlbum } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import axios from 'axios'

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Card from 'react-bootstrap/Card'
import Carousel from 'react-bootstrap/Carousel'

import FloatingWhatsApp from 'react-floating-whatsapp'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import Wave from 'react-wavify'
import ParticlesBg from 'particles-bg'
import ReactFloaterJs from 'react-floaterjs'
import { ParallaxBanner } from 'react-scroll-parallax'
import Marquee from "react-marquee-slider"
import times from "lodash/times"
import { TypeAnimation } from 'react-type-animation'

import { FaFacebookF, FaInstagram, FaTiktok, FaWhatsapp, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'

import original from './img/eureka_logo_ws.png'
import logo from './img/eureka_alt_w.png'
import logo_start from './img/eureka_alt.png'
import logo_wa from './img/eureka_wa-logo-w.jpg'
import astro from './img/eureka_astro_1.webp'
import astro_2 from './img/eureka_astro_2.webp'
import astro_3 from './img/eureka_astro_3.webp'
import astro_4 from './img/eureka_astro_4.webp'
import astro_5 from './img/eureka_astro_5.webp'
import done from './img/done.png'
import globo from './img/eureka_cohete.webp'

import redes from './img/eureka_redes.webp'
import design from './img/eureka_design.webp'
import learn from './img/eureka_learn.webp'
import web from './img/eureka_web.webp'
import photo from './img/eureka_photo.webp'
import clients from './img/eureka_clients.webp'
import video from './img/eureka_video.webp'
import ads from './img/eureka_ads.jpg'

//import 'animate.css';
import "animate.css/animate.min.css";
import "yet-another-react-lightbox/styles.css";
import './App.css';

import p01 from './img/gallery/01.jpg'
import p02 from './img/gallery/02.jpg'
import p03 from './img/gallery/03.jpg'
import p04 from './img/gallery/04.jpg'
import p05 from './img/gallery/05.jpg'
import p06 from './img/gallery/06.jpg'
import p07 from './img/gallery/07.jpg'
import p08 from './img/gallery/08.jpg'
import p09 from './img/gallery/09.jpg'
import p10 from './img/gallery/10.jpg'
import p11 from './img/gallery/11.jpg'
import p12 from './img/gallery/12.jpg'
import p13 from './img/gallery/13.jpg'
import p14 from './img/gallery/14.jpg'
import p15 from './img/gallery/15.jpg'
import p16 from './img/gallery/16.jpg'
import p17 from './img/gallery/17.jpg'
import p18 from './img/gallery/18.jpg'
import p19 from './img/gallery/19.jpg'
import p20 from './img/gallery/20.jpg'
import p21 from './img/gallery/21.jpg'
import p22 from './img/gallery/22.jpg'
import p23 from './img/gallery/23.jpg'
import p24 from './img/gallery/24.jpg'
import p25 from './img/gallery/25.jpg'
import p26 from './img/gallery/26.jpg'
import p27 from './img/gallery/27.jpg'
import p28 from './img/gallery/28.jpg'
import p29 from './img/gallery/29.jpg'
import p30 from './img/gallery/30.jpg'
import p31 from './img/gallery/31.jpg'
import p32 from './img/gallery/32.jpg'
import p33 from './img/gallery/33.jpg'
import p34 from './img/gallery/34.jpg'
import p35 from './img/gallery/35.jpg'
import p36 from './img/gallery/36.jpg'
import p37 from './img/gallery/37.jpg'
import p38 from './img/gallery/38.jpg'

const API_PATH = "https://digital-eureka.com/mailer.php";
const SITE_KEY = "6Le7o4UhAAAAAMtKSidIoVlcrI9n29WAI5ukwSfM";

const photos = [
  "https://digital-eureka.com/clients/client-1.webp",
  "https://digital-eureka.com/clients/client-2.webp",
  "https://digital-eureka.com/clients/client-3.webp",
  "https://digital-eureka.com/clients/client-4.webp",
  "https://digital-eureka.com/clients/client-5.webp",
  "https://digital-eureka.com/clients/client-6.webp",
  "https://digital-eureka.com/clients/client-7.webp",
  "https://digital-eureka.com/clients/client-8.webp",
  "https://digital-eureka.com/clients/client-9.webp",
  "https://digital-eureka.com/clients/client-10.webp",
  "https://digital-eureka.com/clients/client-11.webp",
  "https://digital-eureka.com/clients/client-12.webp",
  "https://digital-eureka.com/clients/client-13.webp",
  "https://digital-eureka.com/clients/client-14.webp",
  "https://digital-eureka.com/clients/client-15.webp",
  "https://digital-eureka.com/clients/client-16.webp",
  "https://digital-eureka.com/clients/client-17.webp",
  "https://digital-eureka.com/clients/client-18.webp",
  "https://digital-eureka.com/clients/client-19.webp",
  "https://digital-eureka.com/clients/client-20.webp",
  "https://digital-eureka.com/clients/client-21.webp",
  "https://digital-eureka.com/clients/client-22.webp",
  "https://digital-eureka.com/clients/client-23.webp",
  "https://digital-eureka.com/clients/client-24.webp",
  "https://digital-eureka.com/clients/client-25.webp",
  "https://digital-eureka.com/clients/client-26.webp",
  "https://digital-eureka.com/clients/client-27.webp",
  "https://digital-eureka.com/clients/client-28.webp",
  "https://digital-eureka.com/clients/client-29.webp",
  "https://digital-eureka.com/clients/client-30.webp",
  "https://digital-eureka.com/clients/client-31.webp",
  "https://digital-eureka.com/clients/client-32.webp",
  "https://digital-eureka.com/clients/client-33.webp",
  "https://digital-eureka.com/clients/client-34.webp",
  "https://digital-eureka.com/clients/client-35.png",
  "https://digital-eureka.com/clients/client-36.png",
  "https://digital-eureka.com/clients/client-37.png",
  "https://digital-eureka.com/clients/client-38.png",
  "https://digital-eureka.com/clients/client-39.png",
  "https://digital-eureka.com/clients/client-40.png",
  "https://digital-eureka.com/clients/client-41.png",
  "https://digital-eureka.com/clients/client-42.png",
  "https://digital-eureka.com/clients/client-43.png",
  "https://digital-eureka.com/clients/client-44.png",
  "https://digital-eureka.com/clients/client-45.png"
];

const services = [
  {
    title: "Gestión de Resdes sociales",
    desc: <div>Sabemos que para ti el tiempo es valioso y que detenerte a <b>crear contenido</b> puede costarte la demora en las operaciones de tu negocio, por ello nosotros <b>nos encargamos de hacerlo por ti</b>.
      <br/><br/>Comenzamos con conocerte, entender tus valores y los de tu marca, <b>analizamos tu mercado</b> y estudiamos a tu competencia, con base en estos principios, seleccionamos la red social ideal para tu negocio, <b>desarrollamos contenidos</b> de valor que ayuden a <b>crear un vínculo</b> entre tu marca y tu audiencia.</div>,
    img: redes
  }, {
    title: "Diseño gráfico digital",
    desc: <div>Tienes tu estrategia muy clara, no requieres que alguien gestione tus redes sociales y solo necesitas que un <b>experto plasme tus ideas en una pieza gráfica</b>, nosotros podemos ayudarte con:
      <br/><br/><div style={{ textAlign: "left" }}><ul><li>Logotipo</li><li>Flyer digital</li><li>Tarjetas de presentación</li><li>Piezas para redes sociales</li><li>Entre otros...</li></ul></div>
    </div>,
    img: design
  }, {
    title: "Producción y edición de videos",
    desc: <div><b>Te ayudamos</b> con el desarrollo de un guion para que al <b>contar tu historia</b> sea más sencillo de comprender.<br/><br/>
    Capturamos los <b>momentos más importantes de tu producto/servicio</b>.<br/><br/>
    Realizamos el trabajo de <b>posproducción acorde al giro de tu negocio</b> y empatado con la plataforma donde se vaya a utilizar.</div>,
    img: video
  }, {
    title: "Páginas Webs y Apps",
    desc: <div>Desarrollamos <b>sitios web a la medida</b> de tu negocio y las necesidades del mismo.<br/><br/><b>Desarrollos de apps</b>: creamos aplicaciones totalmente personalizadas y aptas para sistemas operativos como iOS y Android, con una gran variedad de funcionalidades.</div>,
    img: web
  }, {
    title: "Campañas de clientes potenciales",
    desc: <div>Si tú generas tus contenidos o ya tienes quién lo haga por ti, pero <b>necesitas conseguir más clientes</b>, esta es tu opción.<br/><br/>
    Este tipo de campañas son de las más efectivas que existen hoy en día, dedicada totalmente a <b>generar prospectos bien calificados</b>, ya sea para realizar una compra, llenar un formulario, realizar una acción o hasta descargar una aplicación.</div>,
    img: clients
  }, {
    title: "Campañas de Google Ads",
    desc: <div>Permítenos ayudarte a que tu marca llegue a las personas que están buscando productos o servicios que ofreces, recuerda que Google es el centro de búsqueda más usado por todos en la actualidad.</div>,
    img: ads
  }, {
    title: "Fotografía de producto",
    desc: <div>Sabemos que el <b>producto/servicio</b> que ofreces es grandioso y que solo necesitas que el mundo lo conozca, por eso, <b>te ayudamos a que destaque</b> de entre la competencia.<br/><br/>
    <div style={{ textAlign: "left" }}><ul><li>Conservamos la esencia.</li><li>No excedemos la edición.</li><li>Buscamos el mejor ángulo y la mejor iluminación.</li><li>Hemos fotografiado desde productos miniatura hasta aviones.</li></ul></div></div>,
    img: photo
  }, {
    title: "Cursos y capacitación",
    desc: <div>También contamos con cursos y capacitaciones para que <b>tu mismo o tu equipo</b> de trabajo puedan <b>realizar sus actividades</b>:<br/><br/>
    <div style={{ textAlign: "left" }}><ul><li>Community manager</li><li>Campañas de Facebook e Instagram</li><li>Diseño de social media para no diseñadores</li></ul></div></div>,
    img: learn
  }
]

function Home() {
  const images = { logo, logo_start }
  const astros = [ astro, astro_2, astro_3, astro_4, astro_5 ]
  const gallery = [ p01, p02, p03, p04, p05, p06, p07, p08, p09, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19, p20, p21, p22, p23, p24, p25, p26, p27, p28, p29, p30, p31, p32, p33, p34, p35, p36, p37, p38 ]

  const [ naut, setNaut ] = useState('')
  const [facebookLink, setFacebookLink] = useState('https://www.facebook.com/SomosEureka')
  const [instagramLink, setInstagramLink] = useState('https://www.instagram.com/eureka_marketing/')
  const [tiktokLink, setTiktokLink] = useState('https://www.tiktok.com/@eurekamarketing')
  const [showMessage, setShowMessage] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [textBtn, setTextBtn] = useState('ENVIAR')
  const [showPrivacy, setShowPrivacy] = useState(false)
  const [showService, setShowService] = useState(false)
  const [service, setService] = useState(null)
  
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [comments, setComments] = useState("")

  const [ goingUp, setGoingUp ] = useState(false)
  const [ image, setImage ] = useState(images['logo'])
  const [lightIndex, setLightIndex] = useState(-1)

  const prevScrollY = useRef(0);
  const refHome = useRef(null);
  const refUs = useRef(null);
  const refServices = useRef(null);
  const refPromos = useRef(null);
  const refContact = useRef(null);
  const refGuest = useRef(null);
  const refFoto = useRef(null);

  const photosGallery = [
    { src: p01, width: 1080, height: 1080 },
    { src: p02, width: 1080, height: 1080 },
    { src: p03, width: 1080, height: 1080 },
    { src: p04, width: 1080, height: 1080 },
    { src: p05, width: 4000, height: 5000 },
    { src: p06, width: 4000, height: 5000 },
    { src: p07, width: 3866, height: 4832 },
    { src: p08, width: 3720, height: 5580 },
    { src: p09, width: 3656, height: 5484 },
    { src: p10, width: 3645, height: 5467 },
    { src: p11, width: 3738, height: 5607 },
    { src: p12, width: 5908, height: 3939 },
    { src: p13, width: 2766, height: 2213 },
    { src: p14, width: 2580, height: 3225 },
    { src: p15, width: 2739, height: 3424 },
    { src: p16, width: 3753, height: 5629 },
    { src: p17, width: 3761, height: 5641 },
    { src: p18, width: 3835, height: 5752 },
    { src: p19, width: 3012, height: 3080 },
    { src: p20, width: 5117, height: 3893 },
    { src: p21, width: 3912, height: 3130 },
    { src: p22, width: 3871, height: 5412 },
    { src: p23, width: 3607, height: 5417 },
    { src: p24, width: 3024, height: 3780 },
    { src: p25, width: 3206, height: 2565 },
    { src: p26, width: 3696, height: 2772 },
    { src: p27, width: 3752, height: 2814 },
    { src: p28, width: 6000, height: 4000 },
    { src: p29, width: 4000, height: 5000 },
    { src: p30, width: 3646, height: 4557 },
    { src: p31, width: 4992, height: 3994 },
    { src: p32, width: 4903, height: 3922 },
    { src: p33, width: 6000, height: 4000 },
    { src: p34, width: 6000, height: 4000 },
    { src: p35, width: 5829, height: 3886 },
    { src: p36, width: 5899, height: 3933 },
    { src: p37, width: 6000, height: 4000 },
    { src: p38, width: 5665, height: 3777 }
  ]

  useEffect(() => {
    setNaut(astros[ Math.floor(Math.random() * astros.length) ])
    if ( isMobile ) {
      if( isAndroid ) {
        setFacebookLink('fb://page/1639569549659215')
      }
      else if( isIOS ) {
        setFacebookLink('fb://profile/1639569549659215')
      }
    }

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;

      if(currentScrollY > 10) {
        document.getElementById("ek-navbar").className = "nav-bar-custom navbar navbar-expand-lg fixed-top";
        document.getElementById("brand_image").className = "d-inline-block align-top brand-image";
        setImage(images['logo_start'])

        var navs = document.getElementsByClassName('nav-link-custom');
        for(var i = 0; i < navs.length; i++) {
          navs[i].style.color = '#000';
        }

        var icons = document.getElementsByClassName('nav-icons');
        for(var i = 0; i < icons.length; i++) {
          icons[i].style.color = '#000';
        }
      } else {
        document.getElementById("ek-navbar").className = "nav-bar-custom-start navbar navbar-expand-lg navbar-dark fixed-top";
        document.getElementById("brand_image").className = "d-inline-block align-top brand-image-start";
        setImage(images['logo'])

        var navs = document.getElementsByClassName('nav-link-custom');
        for(var i = 0; i < navs.length; i++) {
          navs[i].style.color = isMobile ? '#000' : '#fff';
        }

        var icons = document.getElementsByClassName('nav-icons');
        for(var i = 0; i < icons.length; i++) {
          icons[i].style.color = isMobile ? '#000' : '#fff';
        }
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  const showServiceContent = ( item ) => {
    setService(item)
    setShowService(true)
  }

  const handleNavClick = (eventKey) => {
    const yOffset = -100; 
    const element = eval(eventKey).current;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        handleOnSubmit(token);
      });
    });
  }

  const handleOnSubmit = (token) => {
    var form = document.getElementById('contactForm');
    var data = new FormData(form);
    data.append('recaptcha_response', token);

    setTextBtn("Enviando...")
    setIsActive(true)

    axios.post(API_PATH, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
    }).then(function(response) {

      if(response.data == "FAIL" || response.data == "ERROR") {
        setTextBtn("ENVIAR")
        setIsActive(false)

        setTitle("Error")
        setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
        setShowMessage(true)
      } else {
        setTextBtn("ENVIAR")
        setIsActive(false)

        setTitle("Mensaje enviado")
        setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
        setShowMessage(true)

        setName("");
        setPhone("");
        setEmail("");
        setComments("");
      }

    }).catch(function(err) {
      setTextBtn("ENVIAR")
      setIsActive(false) 

      setTitle("Error")
      setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
      setShowMessage(true)
    });
  }

  const handleCloseMessage = () => setShowMessage(false);

  return (
    <div className="App">
      <FloatingWhatsApp  
        className="wa-style"
        phoneNumber="5215655484513"
        accountName="Eureka!"
        statusMessage="En linea"
        avatar={logo_wa}
        chatMessage="Hola, ¡Bienvenido a Eureka! Nos alegra que nos escribas. Cuéntanos, ¿cómo podemos ayudarte?"
        allowClickAway
        notification={false}
      />

      <Modal show={showMessage} onHide={handleCloseMessage}>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={handleCloseMessage}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showService} onHide={ () => setShowService(false) } aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton className="modal-service-header"></Modal.Header>
        <Modal.Body className="modal-service-body">
          <div><img loading='lazy' alt={ service != null ? service.title : '' } src={ service != null ? service.img : '' } className="modal-img" width="1000px" height="537px"/></div>
          <br/>
          <div className="modal-service-body-text">
            <div className="animate__animated animate__bounce animate__fadeIn section-title txt-center modal-title-style">{ service != null ? service.title : '' }</div>
            <br/>
            <div className="animate__animated animate__bounce animate__fadeIn animate__delay-1s service-desc txt-center">{ service != null ? service.desc : '' }</div>
          </div>
        </Modal.Body>
      </Modal>

      { !isMobile && 
        <Wave className='home-wave' fill='#fff' paused={false} options={{ height: isMobile ? 800 : 1000, amplitude: 90, speed: 0.15, points: 2 }}></Wave>
      }
      <div className='home-bg'>
        <Navbar id="ek-navbar" collapseOnSelect expand="lg" className="nav-bar-custom-start navbar-dark" fixed="top">
          <Container>
            <Navbar.Brand>
              <img loading='lazy' alt="Eureka!" id="brand_image" src={image} width="200px" height="71px" className="d-inline-block align-top brand-image-start" onClick={() => handleNavClick("refHome")}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className="collapse-custom" id="responsive-navbar-nav">
              <Nav className="me-auto">
              </Nav>
              <Nav onSelect={handleNavClick}>
                <Nav.Link eventKey="refUs">
                  <div className="nav-link-custom">Nosotros</div>
                </Nav.Link>
                <Nav.Link eventKey="refServices">
                  <div className="nav-link-custom">Servicios</div>
                </Nav.Link>

                <Nav.Link eventKey="refFoto">
                  <div className="nav-link-custom">Fotografía de Producto</div>
                </Nav.Link>
                {/*<Nav.Link eventKey="refPromos">
                  <div className="nav-link-custom">Paquetes</div>
                </Nav.Link>*/}
                <Nav.Link eventKey="refContact">
                  <div className="nav-link-custom">Contacto</div>
                </Nav.Link>

                <Nav.Item className="nav-link-icons" onClick={ () =>  window.open(facebookLink) }>
                  <FaFacebookF className="nav-icons"/>
                </Nav.Item>
                <Nav.Item className="nav-link-icons" onClick={ () =>  window.open(instagramLink) }>
                  <FaInstagram className="nav-icons"/>
                </Nav.Item>
                <Nav.Item className="nav-link-icons" onClick={ () =>  window.open(tiktokLink) }>
                  <FaTiktok className="nav-icons"/>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="home-style">
          <ParallaxBanner
              layers={[
                {
                    image: 'https://digital-eureka.com/eureka_galaxy_planets.webp',
                    speed: 15,
                    style: {
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      //maxWidth: '1320px'
                    },
                },
              ]}
              style={{
                /*aspectRatio: '2 / 1',*/
              }}
          > 
          <Container className="home-section" style={{ position: "relative" }} ref={ refHome }>
            <div className="navspace"/>
            <Row className="align-items-center">
              { !isMobile && 
                <Col md ={ 6 } align="center" className="home-height">
                  {/*<img src={ home } className="eureka-img"/>*/}
                  <div className="animate__animated animate__bounce animate__backInLeft animate__delay-1s">
                    <ReactFloaterJs>
                      <img alt="Eureka! Astronauta" src={ naut } className="eureka-img" width="1000px" height="1000px"/>
                    </ReactFloaterJs>
                  </div>
                </Col>
              }
              <Col md ={ 6 }>
                { !isMobile && 
                  <TypeAnimation
                    sequence={[ 1000, 'Hagamos que tus ideas' ]}
                    speed={55}
                    wrapper="h2"
                    className="home-title"
                    cursor={false}
                    repeat={0}
                  />
                }
                { isMobile && 
                  <div className="home-title">Hagamos que tus ideas</div>
                }

                { !isMobile &&
                  <div className="animate__animated animate__bounce animate__fadeIn animate__delay-2s home-title-big">SUCEDAN</div>
                }
                { isMobile &&
                  <div className="animate__animated animate__bounce animate__fadeIn animate__delay-1s home-title-big">SUCEDAN</div>
                }
                <br/>

                { !isMobile && 
                  <TypeAnimation
                    sequence={[ 3000, 'Somos más que una agencia de marketing digital y redes sociales, armamos estrategias a la medida para que atraigas nuevos clientes y fidelices los que ya tienes.' ]}
                    speed={50}
                    wrapper="h2"
                    className="home-subtitle"
                    repeat={0}
                    style={ isMobile ? { height: "100px" } : { height: "72px" }}
                  />
                }
                { isMobile && 
                  <div className="animate__animated animate__bounce animate__fadeIn animate__delay-2s home-subtitle">Somos más que una agencia de marketing digital y redes sociales, armamos estrategias a la medida para que atraigas nuevos clientes y fidelices los que ya tienes.</div>
                }
                <br/>
                <br/>
                <div align={ isMobile ? "center" : "right"}>
                  <div onClick={() => handleNavClick("refContact")} className="btn_action">CONTÁCTANOS</div>
                </div>
              </Col>
            </Row>
          </Container>
          
          <Container className="section-a section-a-home" ref={ refUs }>
            <div className="section-item">
              <Container>
                <Row className="align-items-center">
                  <Col className="section-text-style" md={ 12 }>
                  { !isMobile && 
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn animateOnce">
                      <div className="section-title txt-center" style={{ color: "#fff" }}>
                        ¿Quiénes somos?
                      </div>
                      <div className="section-desc txt-center" style={{ color: "#fff" }}>
                        Somos un grupo de profesionistas apasionados y calificados en el uso de herramientas y plataformas de marketing digital, así como de redes sociales. Tenemos como principal objetivo ayudar a emprendedores y empresarios a crecer sus negocios a través de sus medios digitales por medio de estrategias innovadoras.
                      </div>
                      <br/>
                      <br/>
                      <br/>
                    </AnimationOnScroll> 
                  }
                  { isMobile && 
                    <>
                      <div className="section-title txt-center" style={{ color: "#fff" }}>
                        ¿Quiénes somos?
                      </div>
                      <div className="section-desc txt-center" style={{ color: "#fff" }}>
                        Somos un grupo de profesionistas apasionados y calificados en el uso de herramientas y plataformas de marketing digital, así como de redes sociales. Tenemos como principal objetivo ayudar a emprendedores y empresarios a crecer sus negocios a través de sus medios digitales por medio de estrategias innovadoras.
                      </div>
                    </>
                  }
                  </Col>
                </Row>
              </Container>
            </div>
          </Container>

          </ParallaxBanner>
        </div>
      </div>

      <div>
        <div className="section" align="center">
          {/*<div className="item-content">*/}

          <div className="section-bg">
            
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn animateOnce">
              <Container style={{ position: "relative" }} ref={ refServices }>
                <div className="section-title txt-center">
                  Nuestros Servicios
                </div>
                <br/>
                <div className='section-desc txt-center'>
                  Conoce las soluciones de marketing digital y redes sociales, que tenemos para tu empresa o negocio, no dejes pasar la oportunidad de aumentar tus ventas y conectar con tus clientes.
                </div>
                <br/>
                <br/>
                <Row>
                  { services.map( (item, i) => (
                    <Col md={ 4 } className="col-services" key={i}>
                      <Card className="grow grow-img" onClick={ () => showServiceContent( item ) }>
                        <Card.Img loading='lazy' variant="top" src={ item.img } width="1000px" height="537px" className='card-image' alt={ item.title }/>
                        <Card.Body>
                          <Card.Title className="card-title-style">{ item.title }</Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                  
                </Row>
              </Container>
            </AnimationOnScroll>
          </div>

          <div className="section-bg">
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn animateOnce">
              <Container style={{ position: "relative" }} ref={ refGuest }>
                <Row className='align-items-center'>
                  <Col md={ 5 }>
                    <div className="section-title txt-center">
                      Testimoniales
                    </div>
                    <br/>
                    <div className='section-desc txt-center'>
                    Ellos han confiado en nosotros, y en sus propias palabras comparten su experiencia con nuestro trabajo.
                    </div>
                  </Col>
                  <Col md={ 7 }>
                    <div className="carousel-container">
                      <Carousel indicators={ false } interval={ null }>
                        
                        <Carousel.Item>
                          <div>
                            <ReactPlayer width={ '60%' } url='testimonial_01.mp4' controls={ true }/>
                          </div>  
                          <br/>
                          <div className='card-title-style card-title h5' style={{ color: '#fff' }}>Grupo Óptico Jardines</div>
                        </Carousel.Item>

                        <Carousel.Item>
                          <div>
                          <ReactPlayer width={ '60%' } url='testimonial_02.mp4' controls={ true }/>
                          </div>
                          <br/>
                          <div className='card-title-style card-title h5' style={{ color: '#fff' }}>Vibadenti</div>
                        </Carousel.Item>

                        <Carousel.Item>
                          <div>
                          <ReactPlayer width={ '60%' } url='testimonial_03.mp4' controls={ true }/>
                          </div>
                          <br/>
                          <div className='card-title-style card-title h5' style={{ color: '#fff' }}>Israel Nochebuena</div>
                        </Carousel.Item>

                        <Carousel.Item>
                          <div>
                          <ReactPlayer width={ '60%' } url='testimonial_04.mp4' controls={ true }/>
                          </div>
                          <br/>
                          <div className='card-title-style card-title h5' style={{ color: '#fff' }}>Dental and Coffee</div>
                        </Carousel.Item>

                      </Carousel>
                    </div>
                  </Col>
                </Row>
              </Container>
            </AnimationOnScroll>
          </div>

          <div className='section-bg'>
            <Container ref={ refFoto }>
              <Row>
                <Col>
                <div className="section-title txt-center" style={{ textAlign: 'center' }}>
                  Fotografía Gastronómica
                </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <PhotoAlbum className='bg-album' layout="columns" photos={ photosGallery } columns={ isMobile ? 2 : 5 } onClick={({ index }) => setLightIndex(index)}/>

                  <Lightbox
                    slides={ photosGallery }
                    open={lightIndex >= 0}
                    index={lightIndex}
                    close={() => setLightIndex(-1)}
                    // enable optional lightbox plugins
                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                  />
                </Col>
              </Row>
            </Container>
          </div>

          <div className="sub-section-a">
            <ParticlesBg type="cobweb" color={ [ '#00a0f8' ] } bg={true}/>

            <div>
              {/* 
              <Container className="section-a" ref={ refPromos }>
                <div className="section-title txt-center">
                  Paquetes y Promociones
                </div>
                <div className="section-desc txt-center">
                  Encuentra el plan que más se adapte a tus necesidades. 
                </div>
                <br/>
                <br/>
                <Row>
                  <Col md={ 4 } className="col-promos">
                    <div className="card-container">
                      <div className="card-recomended">&nbsp;</div>
                      <div className="card-title">Sitio web básico</div>
                      <div className="card-subtitle">Muestra tu negocio al mundo.</div>
                      <br/>
                      <div>
                        <span className="card-price">$5,500</span><span className="card-period"></span>
                      </div>
                      <br/>
                      <div className="card-elements">
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Galería de productos<sup>*</sup>.</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Casos de éxito.</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Testimoniales.</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Ubicación.</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Medios de contacto.</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Botón de llamadas, whatsApp y correo.</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Dominio web personalizado.</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={ 4 } className="col-promos">
                    <div className="card-container card-container-recommended">
                      <div className="card-recomended">PROMOCIÓN</div>
                      <div className="card-title">Social media</div>
                      <div className="card-subtitle">Que el mundo te descubra.</div>
                      <br/>
                      <div>
                        <span className="card-price">$4,500</span><span className="card-period">/mes<sup>*</sup></span>
                      </div>
                      <br/>
                      <div className="card-elements">
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>12 diseños.</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>12 historias.</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>5 reels.</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Optimización de Facebook e Instagram.</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Google Business (alta).</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Publicación en redes.</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Estrategia de marketing.</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={ 4 } className="col-promos">
                    <div className="card-container">
                      <div className="card-recomended">&nbsp;</div>
                      <div className="card-title">Fotografía de producto</div>
                      <div className="card-subtitle">Demuéstrale al mundo lo genial de tus productos.</div>
                      <br/>
                      <div>
                        <span className="card-price">$2,300</span><span className="card-period"></span>
                      </div>
                      <br/>
                      <div className="card-elements">
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Duración máxima de la sesión: 3 horas.</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Se entregan las fotografías logradas durante las 3 horas.</span>
                        </div>
                        <div className="p-2">
                          <span><img loading='lazy' alt="Incluye" src={ done } className="card-done" width="48px" height="48px"/>&nbsp;&nbsp;</span>
                          <span>Se entregan las fotos digitales con retoque y en alta resolución.</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              */}
            </div>

            <div>
              <br/><br/>
              <div className="section-a" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <div className="section-title txt-center">
                  Nuestros Clientes
                </div>
                <div className="section-desc txt-center">
                  Ellos confían en nosotros:
                </div>
                <br/>
                <br/>

                <div style={{ height: 90 }}>
                  <Marquee velocity={ isMobile ? 10 : 25 }>
                    {times(22, Number).map(id => (
                      <img loading='lazy' alt="Clientes" className="styled-img" src={photos[id]} key={`marquee-example-people-${id}`} style={{ marginLeft: "87px" }} width="300px" height="188px"/>
                    ))}
                  </Marquee>
                </div>

                <div style={ isMobile ? { height: 1 * 20 } : { height: 1 * 50 }} />
                
                <div style={{ height: 90 }}>
                  <Marquee velocity={ isMobile ? 10 : 25 } direction="ltr">
                    {times(22, Number).map(id => (
                      <img loading='lazy' alt="Clientes" className="styled-img" src={photos[id + 22]} key={`marquee-example-people-${id + 7}`} style={{ marginLeft: "7px", marginRight: "80px", }} width="300px" height="188px"/>
                    ))}
                  </Marquee>
                </div>

              </div>
            </div>
          
            <div className="section-bg-box sub-section-b">
              <Container className="section-b">
                <Row className="align-items-center">
                  <Col md={ 6 }>
                    <ReactFloaterJs>
                      <img loading='lazy' alt="Contáctanos Eureka!" className="d-block w-100" src={ globo } width="1000px" height="1000px"/>
                    </ReactFloaterJs>
                  </Col>
                  <Col md={ 6 }>
                    <div className="contact-container" ref={ refContact }>
                      <div className="section-title txt-center" style={{ color: '#3bffff' }}>
                        Contacto
                      </div>
                      <Container>
                        <Row>
                          <Col md={ 12 } className="col-contact" onClick={ () =>  window.open("https://wa.me/5215621607472") }>
                            <FaWhatsapp className="contact-icons" />&nbsp;&nbsp;
                            <span className='contact-text'>56 2160 7472</span>
                          </Col>
                          <Col md={ 12 } className="col-contact" onClick={ () =>  window.open("mailto:info@digital-eureka.com") }>
                            <FaEnvelope className="contact-icons" />&nbsp;&nbsp;
                            <span className='contact-text'>info@digital-eureka.com</span>
                          </Col>
                        </Row>
                      </Container>
                      <br/>
                      <div style={{ color: '#fff' }}>¡Comencemos a impulsar tus ventas!</div>
                      <br/>
                      <div>
                        <Form id="contactForm" onSubmit={handleOnClick}>

                          <FloatingLabel label="Nombre completo" className="mb-3">
                            <Form.Control required name="name" value={name} onChange={ (e) => setName(e.target.value) } placeholder="-" />
                          </FloatingLabel>

                          <Form.Group className="mb-3">
                            <FloatingLabel label="Correo electrónico">
                              <Form.Control required type="email" value={email} name="email" onChange={ (e) => setEmail(e.target.value) } placeholder="-" />
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <FloatingLabel label="Teléfono">
                              <Form.Control required type="number" value={phone} name="phone" onChange={ (e) => setPhone(e.target.value) } placeholder="-" />
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <FloatingLabel label="Comentario">
                              <Form.Control required name="comments" as="textarea" value={comments} rows="3" className="textarea-style"  onChange={ (e) => setComments(e.target.value) } placeholder="-" />
                            </FloatingLabel>
                          </Form.Group>

                          <br/>
                          <div align="center">
                            <Button disabled={isActive} variant="outline-primary" type="submit">
                              <Spinner
                                className={isActive ? '' : 'hide'}
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              { textBtn }
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              <div className="pre-footer"></div>
            </div>   
          </div>

        </div>
      </div>

    </div>
  );
}

export default Home;
