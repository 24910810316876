import React, { useState, useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { PhotoAlbum } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import FloatingWhatsApp from 'react-floating-whatsapp'

import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa'

import logo from './img/eureka_alt_w.png'
import logo_start from './img/eureka_alt.png'
import logo_wa from './img/eureka_wa-logo-w.jpg'

//import 'animate.css';
import "animate.css/animate.min.css";
import "yet-another-react-lightbox/styles.css";
import './App.css';

import p01 from './img/gallery/01.jpg'
import p02 from './img/gallery/02.jpg'
import p03 from './img/gallery/03.jpg'
import p04 from './img/gallery/04.jpg'
import p05 from './img/gallery/05.jpg'
import p06 from './img/gallery/06.jpg'
import p07 from './img/gallery/07.jpg'
import p08 from './img/gallery/08.jpg'
import p09 from './img/gallery/09.jpg'
import p10 from './img/gallery/10.jpg'
import p11 from './img/gallery/11.jpg'
import p12 from './img/gallery/12.jpg'
import p13 from './img/gallery/13.jpg'
import p14 from './img/gallery/14.jpg'
import p15 from './img/gallery/15.jpg'
import p16 from './img/gallery/16.jpg'
import p17 from './img/gallery/17.jpg'
import p18 from './img/gallery/18.jpg'
import p19 from './img/gallery/19.jpg'
import p20 from './img/gallery/20.jpg'
import p21 from './img/gallery/21.jpg'
import p22 from './img/gallery/22.jpg'
import p23 from './img/gallery/23.jpg'
import p24 from './img/gallery/24.jpg'
import p25 from './img/gallery/25.jpg'
import p26 from './img/gallery/26.jpg'
import p27 from './img/gallery/27.jpg'
import p28 from './img/gallery/28.jpg'
import p29 from './img/gallery/29.jpg'
import p30 from './img/gallery/30.jpg'
import p31 from './img/gallery/31.jpg'
import p32 from './img/gallery/32.jpg'
import p33 from './img/gallery/33.jpg'
import p34 from './img/gallery/34.jpg'
import p35 from './img/gallery/35.jpg'
import p36 from './img/gallery/36.jpg'
import p37 from './img/gallery/37.jpg'
import p38 from './img/gallery/38.jpg'

function FotoProdcuto() {
  const images = { logo, logo_start }

  const [facebookLink, setFacebookLink] = useState('https://www.facebook.com/SomosEureka')
  const [instagramLink, setInstagramLink] = useState('https://www.instagram.com/eureka_marketing/')
  const [tiktokLink, setTiktokLink] = useState('https://www.tiktok.com/@eurekamarketing')

  const [ image, setImage ] = useState(images['logo_start'])
  const [lightIndex, setLightIndex] = useState(-1)

  const refFoto = useRef(null);

  const photosGallery = [
    { src: p01, width: 1080, height: 1080 },
    { src: p02, width: 1080, height: 1080 },
    { src: p03, width: 1080, height: 1080 },
    { src: p04, width: 1080, height: 1080 },
    { src: p05, width: 4000, height: 5000 },
    { src: p06, width: 4000, height: 5000 },
    { src: p07, width: 3866, height: 4832 },
    { src: p08, width: 3720, height: 5580 },
    { src: p09, width: 3656, height: 5484 },
    { src: p10, width: 3645, height: 5467 },
    { src: p11, width: 3738, height: 5607 },
    { src: p12, width: 5908, height: 3939 },
    { src: p13, width: 2766, height: 2213 },
    { src: p14, width: 2580, height: 3225 },
    { src: p15, width: 2739, height: 3424 },
    { src: p16, width: 3753, height: 5629 },
    { src: p17, width: 3761, height: 5641 },
    { src: p18, width: 3835, height: 5752 },
    { src: p19, width: 3012, height: 3080 },
    { src: p20, width: 5117, height: 3893 },
    { src: p21, width: 3912, height: 3130 },
    { src: p22, width: 3871, height: 5412 },
    { src: p23, width: 3607, height: 5417 },
    { src: p24, width: 3024, height: 3780 },
    { src: p25, width: 3206, height: 2565 },
    { src: p26, width: 3696, height: 2772 },
    { src: p27, width: 3752, height: 2814 },
    { src: p28, width: 6000, height: 4000 },
    { src: p29, width: 4000, height: 5000 },
    { src: p30, width: 3646, height: 4557 },
    { src: p31, width: 4992, height: 3994 },
    { src: p32, width: 4903, height: 3922 },
    { src: p33, width: 6000, height: 4000 },
    { src: p34, width: 6000, height: 4000 },
    { src: p35, width: 5829, height: 3886 },
    { src: p36, width: 5899, height: 3933 },
    { src: p37, width: 6000, height: 4000 },
    { src: p38, width: 5665, height: 3777 }
  ]


  return (
    <div className="App">
      <FloatingWhatsApp  
        className="wa-style"
        phoneNumber="5215655484513"
        accountName="Eureka!"
        statusMessage="En linea"
        avatar={logo_wa}
        chatMessage="Hola, ¡Bienvenido a Eureka! Nos alegra que nos escribas. Cuéntanos, ¿cómo podemos ayudarte?"
        allowClickAway
        notification={false}
      />

      <div style={{ height: '100px' }} />
      <div className='home-bg'>
        <Navbar id="ek-navbar" collapseOnSelect expand="lg" className="nav-bar-custom navbar-dark" fixed="top">
          <Container>
            <Navbar.Brand>
              <img loading='lazy' alt="Eureka!" id="brand_image" src={image} width="200px" height="71px" className="d-inline-block align-top brand-image" onClick={() => window.location.href = "/" }/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className="collapse-custom" id="responsive-navbar-nav">
              <Nav className="me-auto">
              </Nav>
              <Nav>
                <Nav.Link href={'/'}>
                  <div className="nav-link-custom" style={{ color: '#000' }}>Nosotros</div>
                </Nav.Link>
                <Nav.Link href={'/'}>
                  <div className="nav-link-custom" style={{ color: '#000' }}>Servicios</div>
                </Nav.Link>

                <Nav.Link href={'/fotografia-producto'}>
                  <div className="nav-link-custom" style={{ color: '#000' }}>Fotografía de Producto</div>
                </Nav.Link>
                {/*<Nav.Link eventKey="refPromos">
                  <div className="nav-link-custom">Paquetes</div>
                </Nav.Link>*/}
                <Nav.Link href={'/'}>
                  <div className="nav-link-custom" style={{ color: '#000' }}>Contacto</div>
                </Nav.Link>

                <Nav.Item className="nav-link-icons" onClick={ () =>  window.open(facebookLink) }>
                  <FaFacebookF className="nav-icons"/>
                </Nav.Item>
                <Nav.Item className="nav-link-icons" onClick={ () =>  window.open(instagramLink) }>
                  <FaInstagram className="nav-icons"/>
                </Nav.Item>
                <Nav.Item className="nav-link-icons" onClick={ () =>  window.open(tiktokLink) }>
                  <FaTiktok className="nav-icons"/>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

      </div>

      <div>
        <div className="section" align="center">

          <div className='section-bg'>
            <Container ref={ refFoto }>
              <Row>
                <Col>
                <div className="section-title txt-center" style={{ textAlign: 'center' }}>
                  Fotografía Gastronómica
                </div>
                <div className='section-desc'>
                  <b>De la vista, nace el antojo… y las ventas.</b>
                </div>
                <br/>
                <div >
                  <div className='section-desc'>Aquí te mostramos una selección de nuestro trabajo en fotografía gastronómica. Cada imagen está diseñada para destacar los sabores, texturas y el atractivo visual de cada platillo, ayudando a que los clientes se enamoren de tu cocina desde la primera mirada.</div>
                  <br/>
                  <div className='section-desc'>¿Quieres que tus productos brillen de esta manera?</div>
                  <br/>
                  <div className='section-desc'><b>Contáctanos y hagamos que tu negocio se vea tan delicioso como sabe.</b></div>
                  <br/><br/>
                </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <PhotoAlbum className='bg-album' layout="columns" photos={ photosGallery } columns={ isMobile ? 2 : 5 } onClick={({ index }) => setLightIndex(index)}/>

                  <Lightbox
                    slides={ photosGallery }
                    open={lightIndex >= 0}
                    index={lightIndex}
                    close={() => setLightIndex(-1)}
                    // enable optional lightbox plugins
                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                  />
                </Col>
              </Row>
            </Container>
          </div>

        </div>
      </div>

    </div>
  );
}

export default FotoProdcuto;
