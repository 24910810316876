import React, { useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Carousel from 'react-bootstrap/Carousel'

import FloatingWhatsApp from 'react-floating-whatsapp'

import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa'

import logo from './img/eureka_alt_w.png'
import logo_start from './img/eureka_alt.png'
import logo_wa from './img/eureka_wa-logo-w.jpg'

//import 'animate.css';
import "animate.css/animate.min.css";
import './App.css';

function Testimoniales() {
  const images = { logo, logo_start }

  const [facebookLink, ] = useState('https://www.facebook.com/SomosEureka')
  const [instagramLink, ] = useState('https://www.instagram.com/eureka_marketing/')
  const [tiktokLink, ] = useState('https://www.tiktok.com/@eurekamarketing')

  const [ image, ] = useState(images['logo_start'])
  const [lightIndex, setLightIndex] = useState(-1)

  const refFoto = useRef(null);


  return (
    <div className="App">
      <FloatingWhatsApp  
        className="wa-style"
        phoneNumber="5215655484513"
        accountName="Eureka!"
        statusMessage="En linea"
        avatar={logo_wa}
        chatMessage="Hola, ¡Bienvenido a Eureka! Nos alegra que nos escribas. Cuéntanos, ¿cómo podemos ayudarte?"
        allowClickAway
        notification={false}
      />

      <div style={{ height: '150px' }} />
      <div className='home-bg'>
        <Navbar id="ek-navbar" collapseOnSelect expand="lg" className="nav-bar-custom navbar-dark" fixed="top">
          <Container>
            <Navbar.Brand>
              <img loading='lazy' alt="Eureka!" id="brand_image" src={image} width="200px" height="71px" className="d-inline-block align-top brand-image" onClick={() => window.location.href = "/" }/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className="collapse-custom" id="responsive-navbar-nav">
              <Nav className="me-auto">
              </Nav>
              <Nav>
                <Nav.Link href={'/'}>
                  <div className="nav-link-custom" style={{ color: '#000' }}>Nosotros</div>
                </Nav.Link>
                <Nav.Link href={'/'}>
                  <div className="nav-link-custom" style={{ color: '#000' }}>Servicios</div>
                </Nav.Link>

                <Nav.Link href={'/fotografia-producto'}>
                  <div className="nav-link-custom" style={{ color: '#000' }}>Fotografía de Producto</div>
                </Nav.Link>
                {/*<Nav.Link eventKey="refPromos">
                  <div className="nav-link-custom">Paquetes</div>
                </Nav.Link>*/}
                <Nav.Link href={'/'}>
                  <div className="nav-link-custom" style={{ color: '#000' }}>Contacto</div>
                </Nav.Link>

                <Nav.Item className="nav-link-icons" onClick={ () =>  window.open(facebookLink) }>
                  <FaFacebookF className="nav-icons"/>
                </Nav.Item>
                <Nav.Item className="nav-link-icons" onClick={ () =>  window.open(instagramLink) }>
                  <FaInstagram className="nav-icons"/>
                </Nav.Item>
                <Nav.Item className="nav-link-icons" onClick={ () =>  window.open(tiktokLink) }>
                  <FaTiktok className="nav-icons"/>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

      </div>

      <div>
        <div className="section" align="center">

          <Container style={{ position: "relative" }}>
            <Row className='align-items-center'>
              <Col md={ 5 }>
                <div className="section-title txt-center">
                  Testimoniales
                </div>
                <br/>
                <div className='section-desc txt-center'>
                  Ellos han confiado en nosotros, y en sus propias palabras comparten su experiencia con nuestro trabajo.
                </div>
              </Col>
              <Col md={ 7 }>
                <div className="carousel-container">
                  <Carousel indicators={ false } interval={ null }>
                    
                    <Carousel.Item>
                      <div>
                        <ReactPlayer width={ '60%' } url='testimonial_01.mp4' controls={ true }/>
                      </div>  
                      <br/>
                      <div className='card-title-style card-title h5' style={{ color: '#fff' }}>Grupo Óptico Jardines</div>
                    </Carousel.Item>

                    <Carousel.Item>
                      <div>
                      <ReactPlayer width={ '60%' } url='testimonial_02.mp4' controls={ true }/>
                      </div>
                      <br/>
                      <div className='card-title-style card-title h5' style={{ color: '#fff' }}>Vibadenti</div>
                    </Carousel.Item>

                    <Carousel.Item>
                      <div>
                      <ReactPlayer width={ '60%' } url='testimonial_03.mp4' controls={ true }/>
                      </div>
                      <br/>
                      <div className='card-title-style card-title h5' style={{ color: '#fff' }}>Israel Nochebuena</div>
                    </Carousel.Item>

                    <Carousel.Item>
                      <div>
                      <ReactPlayer width={ '60%' } url='testimonial_04.mp4' controls={ true }/>
                      </div>
                      <br/>
                      <div className='card-title-style card-title h5' style={{ color: '#fff' }}>Dental and Coffee</div>
                    </Carousel.Item>

                  </Carousel>
                </div>
              </Col>
            </Row>
          </Container>

        </div>
        <div style={{ height: '100px' }} />
      </div>

    </div>
  );
}

export default Testimoniales;
