import React, { useState, useEffect, useRef } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './Home';
import Footer from './Footer';
import FotoProdcuto from './FotoProducto';
import Testimoniales from './Testimoniales';

import './App.css';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="fotografia-producto" element={<FotoProdcuto />} />
          <Route path="testimoniales" element={<Testimoniales />} />
        </Routes>
      </BrowserRouter>

      <Footer />
    </div>
  );
}

export default App;
