import React, { useState, useEffect, useRef } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'

import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa'

import original from './img/eureka_logo_ws.png'

import './App.css';

function Footer() {
  const [facebookLink, setFacebookLink] = useState('https://www.facebook.com/SomosEureka')
  const [instagramLink, setInstagramLink] = useState('https://www.instagram.com/eureka_marketing/')
  const [tiktokLink, setTiktokLink] = useState('https://www.tiktok.com/@eurekamarketing')
  const [showPrivacy, setShowPrivacy] = useState(false)

  return (
    <div className="App">
      <div className='footer'>
          <Container>
            <Row>
              <Col md={ 2 }>
              </Col>
              <Col md={ 8 }>
                <img loading='lazy' alt="Eureka!" className="footer-logo" src={ original } width="200px" height="200px"/>
                <br/><br/>
                <div>
                  ¿Qué espera la gente de tu marca? Ve más allá, innovemos y creemos juntos. Nuestro objetivo es brindarte las mejores soluciones de crecimiento para tu negocio. En Eureka! impulsamos tu crecimiento y cultivamos la relación entre tus clientes y tu producto/servicio.
                </div>
                <br/>
                <div>
                  <div className='footer-title'>Siguenos en redes sociales</div>
                  <br/>
                  <FaFacebookF className="footer-icons" onClick={ () =>  window.open(facebookLink) } />&nbsp;&nbsp;
                  <FaInstagram className="footer-icons" onClick={ () =>  window.open(instagramLink) } />&nbsp;&nbsp;
                  <FaTiktok className="footer-icons" onClick={ () =>  window.open(tiktokLink) } />
                </div>
                <br/>
                <div className='footer-title' onClick={ () => setShowPrivacy(true) }>
                  Aviso de privacidad
                </div>
              </Col>
              <Col md={ 2 }>
              </Col>
            </Row>
          </Container>
      </div>

      <Modal show={showPrivacy} onHide={ () => setShowPrivacy(false) } size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton className="modal-service-header"></Modal.Header>
        <Modal.Body className="modal-service-body">
          <br/>
          <div className="modal-service-body-text">
            <div className="policy-title modal-title-style">AVISO DE PRIVACIDAD PARA CLIENTES</div>
            <br/>
            <div className="policy-desc">
              De conformidad con lo establecido en el Artículo 17, fracción II de la Ley Federal de Protección de Datos Personales en posesión de los particulares le informamos que DPA DIGITAL MARKETING S DE RL DE CV (el "Responsable"), con domicilio en Av. Cerezos #66 San Juan Ixtacala, Atizapán de
              Zaragoza, C.P. 52928 Estado de México y portal de internet https://digital-eureka.com/ Tratará los datos personales que recabe de usted en su carácter de cliente y/o usuario, mismos que serán utilizados para identificarlo con las siguientes finalidades:
              Enviarle promociones e información generada por el "Responsable".<br/>
              Para fines estadísticos, publicitarios, promocionales, mercadológicos o de prospección comercial.<br/>
              Información sobre los servicios que el "Responsable" le proporciona.<br/>
              Enviarle facturas o alguna otra información derivada de la relación comercial.<br/>
              Atender y dar seguimiento a quejas.<br/>
              Llevar un récord de contrataciones por parte de su compañía de alguno de los servicios que ofrece el "Responsable".<br/>
              Por lo tanto y para alcanzar las finalidades antes expuestas, se tratarán los siguientes datos personales: nombre, teléfono y correo electrónico.
              <br/><br/>
              <b>Sistemas utilizamos para la captura de estos datos</b>
              <br/>
              1) Formularios de suscripción a contenidos: dentro de la web https://digital-eureka.com/ existen varios formularios para activar la suscripción  con la finalidad de enviar campañas de marketing por correo electrónico, gestión de suscripciones y envío de boletines o noticias.
              2) Formulario de comentarios: La web incluye un formulario cuya finalidad es comentar los artículos y dar su opinión respetando la libertad de expresión. El usuario podrá publicar comentarios en los post que se publiquen. Los datos personales introducidos en el formulario para insertar estos comentarios serán utilizados exclusivamente para moderarlos y publicarlos,
              recabando igualmente estos datos que se almacenarán en los servidores de Godaddy.
              3) Formulario de contacto: Existe un formulario de contacto cuya finalidad es la respuesta de consultas, sugerencias o contacto profesional. En este caso se utilizará la dirección de correo electrónico para responder a las mismas y enviar la información que el usuario requiera a través de la web, estos datos se almacenarán en los servidores de Godaddy.
              4) Cookies: Cuando el usuario se registra o navega en esta web, se almacenan “cookies”, El usuario puede consultar en cualquier momento la política de cookies para ampliar información sobre el uso de cookies y como desactivarlas.
              6) Los usuarios podrán darse de baja en cualquier momento de los servicios prestados por https://digital-eureka.com/ desde la misma Newsletter a través de un formulario de cancelación.
              7) Sistemas de rastreo utilizadas en este sitio: Google (Analytics), en https://digital-eureka.com/ también se estudian las preferencias de sus usuarios, sus características demográficas, sus patrones de tráfico, y otra información en conjunto para comprender mejor quiénes constituyen su audiencia y qué es lo que esta necesita.
              <br/><br/>
              El rastreo de las preferencias de nuestros usuarios también nos ayuda a mostrarle los avisos publicitarios más relevantes.
              <br/><br/>
              Asimismo, informamos que para la captación de suscriptores y clientes utilizamos Facebook Ads teniendo instalado el correspondiente Pixel de Facebook, por lo que al generar un anuncio, se puede segmentar el público por el lugar, datos demográficos, intereses, etc por lo que los datos obtenidos por esta plataforma, estarían sujetos a esta política de privacidad desde el momento en que el usuario deja sus datos para unirse al boletín de https://digital-eureka.com/
              <br/><br/>
              <b>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</b>
              Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del
              uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de
              que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases
              de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse
              al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos
              ARCO.
              <br/><br/>
              Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del
              siguiente medio:
              <br/>
              Enviando un correo electrónico a info@digital-eureka.com

              El responsable no vende ni venderá datos personales de los usuarios a otras empresas ni terceros.
              El responsable compartirá con terceros los datos que posee, solo para cumplir con los servicios contratados por algún usuario, enviar la newsletter, cumplir con exigencias legales o para la administración del sitio web. A este efecto, se proporcionarán los debidos acuerdos de confidencialidad entre las partes.
              Los enlaces a terceros que se puedan encontrar en el sitio web poseen políticas de privacidad ajenas a el responsable. El acceso a estos sitios deberá ser responsabilidad del usuario, siendo su responsabilidad conocerlas y su decisión aceptarlas o no.
              Los datos de contacto de la persona o departamento de datos personales, que está cargo de dar trámite a las solicitudes de derechos ARCO (GDPR), son los siguientes:
              a) Nombre de la persona o departamento de datos personales: Pablo Monroy Espejelb) Domicilio: Av Cerezos #66 San Juan Ixtacala, Atizapán de Zaragoza, Estado de México.
              c) Correo electrónico: info@digital-eureka.com
              <br/><br/>
              ¿Cómo puede limitar el uso o divulgación de su información personal?
              Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios: Enviar un correo a info@digital-eureka.com
              <br/><br/>
              El uso de tecnologías de rastreo en nuestro portal de internet. Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de internet, asÌ como brindarle un mejor servicio y experiencia al navegar en nuestra página.
              Los datos personales que recabamos a través de estas tecnologías, los utilizaremos para los siguientes fines: Ofrecer servicios y productos relevantes para el usuario. Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes: Identificadores, nombre de usuario y contraseñas de una sesión, Idioma preferido por el usuario, región en la que se encuentra el usuario. Tipo de navegador del usuario, Tipo de sistema operativo del usuario, Fecha y hora del inicio y final de una sesión de un usuario, Páginas web visitadas por un usuario, Búsquedas realizadas por un usuario, Publicidad revisada por un usuario, Listas y hábitos de consumo en páginas de compras.
              <br/><br/>
              En relación con la gestión de tus datos asociados a los perfiles sociales de El Responsable, el ejercicio del derecho de acceso, dependerá de la funcionalidad de la red social y las posibilidades de acceso a la información de los perfiles de los usuarios. Con relación a los derechos de acceso y rectificación, le recomendamos que sólo podrá satisfacerse en relación a aquella información que se encuentre bajo el control de El Responsable Además podrá dejar de interactuar, seguir o recibir información de los perfiles sociales de El Responsable, eliminar los contenidos que dejen de interesarle o restringir con quien comparte sus conexiones, mediante los mecanismos estipulados en las diferentes redes sociales.
              <br/><br/>
              <b>¿Cómo puede conocer los cambios en este aviso de privacidad?</b>
              El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
              <br/><br/>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: correo electrónico a info@digital-eureka.com.
              <br/><br/>El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente: se actualizará la sección de aviso de privacidad en la página web.
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  );
}

export default Footer;
